import { Picture, pictureBuilder } from "models/Picture";
import {
  CloudDomain,
  Domain,
  DynamicPage,
  TextComponent,
  WebsiteConfiguration,
  WebsiteConfigurationComponent,
  WebsiteConfigurationFormValues,
  WebsiteConfigurationGlobal,
  WebsiteConfigurationPreview,
  WebsiteSettingsFormValues,
  WebsiteStatus,
  WebsiteStatusTask,
  websiteSettings,
} from "models/WebsiteConfiguration";
import { listFormatter } from "utils";
import { formatPicture } from "./picture";

export const formatWebsiteStatusTask = (result: any): WebsiteStatusTask => ({
  labels: result.labels,
  status: result.status,
  routes: result.routes,
});

export const formatWebsiteStatus = (result: any): WebsiteStatus => ({
  availWebsiteByClient: result.availWebsiteByClient,
  mandatoryTasks:
    result.mandatoryTasks &&
    listFormatter<WebsiteStatusTask>(
      result.mandatoryTasks,
      formatWebsiteStatusTask
    ),
  optionalTasks:
    result.optionalTasks &&
    listFormatter<WebsiteStatusTask>(
      result.optionalTasks,
      formatWebsiteStatusTask
    ),
  mandatoryTasksAreTrue: result.mandatoryTasksAreTrue,
});

export const formatWebsiteConfigurationGlobal = (
  result: any
): WebsiteConfigurationGlobal => ({
  logo: result.general.logo && pictureBuilder(result.general.logo),
  logoBanner:
    result.general.logoBanner && pictureBuilder(result.general.logoBanner),
  backgroundColor: result.general.backgroundColor,
});

export const formatWebsiteConfigurationPreview = (
  result: any
): WebsiteConfigurationPreview => ({
  picture: result.preview.picture && pictureBuilder(result.preview.picture),
  title: {
    label: result.preview.title,
    color: result.preview.titleColor,
  },
});

export const formatWebsiteSettings = (result: any): websiteSettings => ({
  myDomains: result.myDomains
    ? listFormatter<Domain>(result.myDomains, formatDomain)
    : [],
});

export const formatWebsiteSettingsFormValues = (
  websiteSettings: websiteSettings
): WebsiteSettingsFormValues => ({
  myDomains: websiteSettings.myDomains,
});

export const formatCloudDomain = (result: any): CloudDomain => ({
  name: result.name,
  price: result.price,
  availability: result.availability,
});

export const formatDomain = (result: any): Domain => ({
  id: result.id,
  name: result.name,
  price: result.price,
  status: result.status,
  transferKey: result.transferKey,
});

export const formatTextComponent = (result: any): TextComponent => ({
  value: result.value,
  size: result.size,
  bold: result.bold,
  underline: result.underline,
  italic: result.italic,
  align: result.align,
});

export const formatComponent = (
  result: any
): WebsiteConfigurationComponent => ({
  type: result.type,
  pictures:
    result.pictures && listFormatter<Picture>(result.pictures, formatPicture),
  titles:
    result.titles &&
    listFormatter<TextComponent>(result.titles, formatTextComponent),
  texts:
    result.texts &&
    listFormatter<TextComponent>(result.texts, formatTextComponent),
  isMandatory: result.isMandatory,
  textAdaptation: result.textAdaptation,
});

export const formatDynamicPage = (result: any): DynamicPage => ({
  name: result.name,
  components:
    result.components &&
    listFormatter<WebsiteConfigurationComponent>(
      result.components,
      formatComponent
    ),
  dynamicPage: result.dynamicPage,
});

export const formatWebsiteConfiguration = (
  result: any
): WebsiteConfiguration => ({
  template: result.template,
  theme: result.theme,
  general: result.general && formatWebsiteConfigurationGlobal(result),
  preview: result.preview && formatWebsiteConfigurationPreview(result),
  dynamicPages:
    result.dynamicPages &&
    listFormatter<DynamicPage>(result.dynamicPages, formatDynamicPage),
});

export const formatWebsiteConfigurationFormValues = (
  websiteConfiguration: WebsiteConfiguration
): WebsiteConfigurationFormValues => ({
  template: websiteConfiguration.template,
  theme: websiteConfiguration.theme,
  general: websiteConfiguration.general,
  preview: websiteConfiguration.preview,
  dynamicPages:
    websiteConfiguration.dynamicPages &&
    websiteConfiguration.dynamicPages.length > 0
      ? listFormatter<DynamicPage>(
          websiteConfiguration.dynamicPages,
          formatDynamicPage
        )
      : [],
});

export const CloudDomainBuilder = (
  values: Partial<CloudDomain> = {}
): CloudDomain => ({
  name: "",
  availability: "",
  ...values,
});
